import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj31 = () => {
  return (
    <Layout>
      <SEO title="Fun with Calender" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-31">
          <div className="py-1-heading">
            <h1>Fun with Calender</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Fun%20With%20Calenderm"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              As the name suggest...it's fun...
              <br />
              <br />
              There is two tasks :- <br />
              <br />
              1. Given a date, can you tell what day of week it falls on ?<br />
              <br />
              2.What is the current time as per diffrent time zones ?<br />
              <br />
              That's all, we are going to do with python...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Calender</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Datetime</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Pytz</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj31
